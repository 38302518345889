import React from "react"

import Layout from "../components/Layout"
import LegalDisclaimer from "../components/LegalDisclaimer"
import SEO from "../components/seo"

const LegalDisclaimerPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Legal Disclaimer" />
    <LegalDisclaimer />
  </Layout>
)

export default LegalDisclaimerPage
